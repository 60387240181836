.service-description{
  min-height: 100vh;
  text-align: left;
}

.service-description .cta{
  margin-top: 3rem;
}

.img-header{
  width: 100%;
  height: 25rem;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.img-header h1{
  font-size: 5rem;
  font-weight: 800;
  padding: 8rem;
  padding-bottom: 0rem;
  color: #4c4c4c;
}

.img-header h2{
  font-size: 1.6rem;
  padding-left: 8rem;
  color: #8b8b8b;
  width: 65%;
}

@media screen and (max-width: 760px) {
  .img-header h1{
    padding-left: 0rem;
    font-size: 10vw;
  }
  .img-header h2{
    padding-left: 0rem !important;
    font-size: 1.2rem;
    color: #8b8b8b;
    width: 75%;
  }

  .card{
    display: none !important;
  }
  .service-detail-text{
    margin-left: 0 !important;
  }
}

.list-group{
  margin-bottom: 0 !important;
}

.card {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    min-width: 0 !important;
    word-wrap: break-word !important;
    box-shadow: none;
    margin-bottom: 3rem;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
}

.card-body {
    padding: 1.5rem;
    flex: 1 1 auto;
}

.service-detail-text{
  text-align: left;
  margin-left: 5rem;
}

.service-detail-text h4{
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.service-detail-text h5{
  padding-top: 2rem;
  font-weight: 700;
  text-align: left;
}

.phone-number .number-button{
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  border-color: #bc0000;
  color: #fff;
  background: #bc0000 !important;
  padding: 1rem;
  border-radius: 2px;
}

.service-detail-text p{
  padding-bottom: 2rem;
  border-bottom: 1px solid #ccc;
}

.service-detail-text a{
  color: #484848;
  text-align: right;
  text-decoration: underline;
}

.text-right{
  margin-bottom: 7rem;
}

@media screen and (max-width: 1000px) {
  .service-menu{
    display: none;
  }

  .service-detail-text{
    padding-left: 2rem;
  }
}
