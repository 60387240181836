.call-to-action{
  background: #0072bc;
  color: white !important;
  margin-bottom: 4rem;
}

.call-to-action .btn{
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  border-color: #bc0000 !important;
  background: #bc0000 !important;
  color: white !important;
  padding: 1rem;
}

.call-to-action .btn:hover{
  border-color: #e20707;
  color: #fff;
  background: #e20707 !important;
}

.call-to-action h1{
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0;
  color: white !important;
}

.call-to-action h2{
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0;
  color: white !important;
}

.call-to-action .secondary-button{
  border-color: white !important;
  color: white;
  padding: 1rem !important;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 1rem;
  background: transparent !important;
}

.call-to-action .secondary-button:hover{
  border-color: #0173bb !important;
  color: #fff;
  background: #0088de !important;
}

@media (max-width: 767px) {
  .call-to-action h1{
    display: none;
  }
  .call-to-action h2{
    font-size: 1.8rem;
  }
}
