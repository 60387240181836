.navbar {
  /* padding: 1.5rem 3rem 1.5rem 3rem !important; */
  box-shadow: none;
  background-color: transparent;
}

.navbar strong{
  text-transform: uppercase;
  font-weight: 900;
}

.navbar a{
  color: white !important;
}

.navbar .dropdown-menu a{
  color: #4c4c4c !important;
}

.top-nav-collapse {
  background-color: white;
  padding: .5rem 3rem .5rem 3rem !important;
}

.service-header {
  background-color: white;
  padding: .5rem 3rem .5rem 3rem !important;
}

.service-header a{
  color: #4c4c4c !important;
}

.top-nav-collapse a{
  color: #4c4c4c !important;
}

@media screen and (max-width: 760px) {
  .navbar {
      padding: .8rem !important;
      background-color: white;
      box-shadow: 0 0px 0px 0 rgba(0,0,0,.16), 0 0px 2px 0 rgba(0,0,0,.12);
  }
  .navbar a{
    font-size: .8rem;
    color: #4c4c4c !important;
  }
  .navbar-toggler {
    color: #4c4c4c !important;
  }
}
