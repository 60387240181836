#testimonials {
  margin-bottom: 2rem;
  text-align: left
}

#testimonials .section-header p{
  width: 50%
}

#testimonials .owl-stage-outer {
    width: 100%;
}

#testimonials .owl-nav {
    margin-top: 0;
}

#testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 0 30px;
  margin: 30px 15px;
  text-align: left;
  min-height: 350px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

#testimonials .testimonial-item .testimonial-img {
  width: 110px;
  border-radius: 20%;
  border: 4px solid #fff;
  margin-bottom: 5px;
}

#testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

#testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
  margin-bottom: 5px;
}

.owl-prev {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    margin-left: -20px !important;
    display: block !important;
    border:0px solid black;
}

.owl-next {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    right: -25px;
    display: block !important;
    border:0px solid black;
}
.owl-prev span, .owl-next span {font-size: 2rem; font-weight: 700; transform : scale(1,6); color: #ccc;}

@media (max-width: 767px) {
  #testimonials .section-header p{
    width: 100%;
  }
}
