.cta {
  height: 18rem;
  background-color: #0072bc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-data{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cta-data h1{
  line-height: 1rem;
}

.cta-data h3{
  font-size: 4rem;

  margin:0;
  color: white !important;
}

.cta-data h5{
  margin:0;
  margin-bottom: 1rem;
  color: white !important;
}

.cta .btn {
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  border-color: #bc0000;
  color: white;
  background: #bc0000 !important;
  padding: 1rem;
}

.cta .btn:hover{
  border-color: #bc0000;
  color: #fff;
  background: #bc0000 !important;
}


@media screen and (max-width: 760px) {
  .cta-data h3{
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
}
