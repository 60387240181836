#clients {
  margin-bottom: 4rem;
}

#clients  h2{
  text-align: left;
}

#clients img {
  max-width: 50%;
  opacity: 0.5;
  transition: 0.3s;
  padding: 15px 0;
}

#clients img:hover {
  opacity: 1;
}

#clients .owl-nav, #clients .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#clients .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#clients .owl-dot.active {
  background-color: #50d8af;
}

.clients-carousel{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.clients-carousel .owl-item{
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.owl-stage-outer{
  width: 100vw;
}

.owl-stage{
  width: 100vw;
}

#clients .owl-prev{
  margin-left: 25px !important;
  top: 0 !important;
}

#clients .owl-next{
  margin-right: 25px !important;
  top: 0 !important;
  right: 0;
}
