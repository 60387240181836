.about-us h2{
  text-transform: capitalize;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.about-us p:first-child{
  margin-bottom: 4rem;
}

.about-us p:last-child{
  margin-bottom: 4rem;
}
