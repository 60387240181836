footer {
  border-top: 1px solid rgba(0,0,0,0.05);
  padding: 120px 0;
  padding-bottom: 80px;
  color: white;
  background: #525252;
  text-align: left;
}

footer ul{
  padding: 0;
 list-style-type: none;
}

footer a{
  color: white;
  font-size: .8rem;
  line-height: 1.4rem;
  font-weight: 700;
}

footer h5{
  font-weight: 800;
}

footer p{
  color: white;
}

footer a:hover{
  text-decoration: underline !important;
  color: white;
}

.socials-widget {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.socials-widget a:hover {
  color: #0072bc;
}

footer .row>div {
  margin-bottom: 1rem;
}
