.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

body {
  background: #ffffff;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}



/*---------------------------------------
   TYPOGRAPHY
-----------------------------------------*/

h1,h2,h3,h4,h5,h6 {
  font-weight: bold;
  line-height: inherit;
}

h1 {
  color: #252525;
  font-size: 3em;
  line-height: 50px;
}

h2 {
  color: #353535;
  font-size: 2em;
  padding-bottom: 10px;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 0;
}

h3,
h3 a {
  color: #454545;
}

p {
  color: #757575;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
}



/*---------------------------------------
   GENERAL
-----------------------------------------*/

html{
  -webkit-font-smoothing: antialiased;
}

a {
  color: #252525;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none !important;
}

a:hover, a:active, a:focus {
  color: #ce3232;
  outline: none;
}

/* ::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #000000;
} */

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  margin: 0;
}

.section-title h4 {
  color: #bfbdbd;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
}

.overlay {
  background: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-header h2 {
  text-align: left;
}
