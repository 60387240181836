header {
  position: relative;
  background-size: cover;
  height: 89vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header .container {
  justify-content: flex-start;
  display: flex;
  position: relative;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

.header-content {
  display: flex;
  flex-direction: column;
  height: 20rem;
  width: 50rem;
  justify-content: center;
  padding: 2rem;
  margin-top: 3rem;
  text-align: left;
}

.header-content h1 {
  text-transform: uppercase;
  font-size: 4.5rem;
  line-height: 4.5rem;
  letter-spacing: -1px;
  text-align: left;
  margin-bottom: 1rem;
  color: white;
  font-weight: 900;
}

.header-content h2 {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.7rem;
  width: 55%;
}

.header-content .btn:hover{
  border-color: #e20707;
  color: #fff;
  background: #e20707 !important;
}

.header-content .secondary-button{
  border-color: white;
  color: white;
  padding: 1rem !important;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 1rem;
}

.header-content .secondary-button:hover{
  border-color: #0173bb;
  color: #fff;
  background: #0173bb !important;
}

@media (max-width: 767px) {
  header {
    height: 75vh;
  }

  header .container{
    justify-content: center;
    display: flex;
    margin-top: 2rem;
  }

  .header-content {
    display: flex;
    flex-direction: column;
    width: 80vw !important;
    height: 55vh !important;
    padding: 0 2rem 0 2rem;
    justify-content: center;
    text-align: left;
    margin-top: 0;
    margin-right: 0;
    margin: auto;
  }

  .header-content h1 {
    font-size: 2rem;
    line-height: 2rem;
  }

  .header-content .btn {
    padding: 1rem;
  }
}

@media (pointer: coarse) and (hover: none) {

  header {
    background: url('/planesstart.png') black no-repeat center center scroll;
    background-size: cover;
  }
  header video {
    display: none;
  }
}

@media (max-width: 400px) {
  header {
    height: 95vh;
  }

  .header-content {
    height: 75rem;
    width: 85vw !important;
    height: 65vh !important;
    padding: 0px 1rem 0 1rem;
  }

  .header-content h1{
    font-size: 2.8rem;
    line-height: 2.8rem;
  }

  .header-content h2{
    width: 100%
  }

  .header-content .btn{
    font-size: .8rem !important;
    padding: .8rem !important;
  }
}
