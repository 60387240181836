.steps div{
  text-align: left;
}

.steps{
  text-align: left;
  margin-bottom: 4rem;
}

.steps h2{
  text-align: left;
  padding-bottom: 1rem;
}

.steps h5{
font-size: 3rem;
color: #757575;
}
