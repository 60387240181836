.services{
  margin-bottom: 2rem;
  text-align: left;
}

.service-grid h3{
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: underline;
}

.service-grid .service{
  margin: 0 0 2rem 0;
  margin-left: auto;
}
