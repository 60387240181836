.pricing{
  text-align: left;
}

.pricing .img-header{
  height: 18rem;
}

.pricing p:last-child{
  margin-bottom: 4rem;
}

.pricing ol:last-child{
  margin-bottom: 4rem;
}

.pricing h2{
  text-align: left;
  margin-top: 2rem;
}

ul, ol{
  color: #757575;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
}
