.quote-form{
  background: #0072bc;
  color: white !important;
  margin-bottom: 4rem;
}

.quote-form h1{
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 0;
  color: white !important;
}

.quote-form h2{
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
  color: white !important;
}

.quote-input{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.quote-form .btn{
  width: 100%;
  margin: 0;
  background:#bc0000 !important;
  box-shadow: none;
  border: solid 1px #bc0000;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .quote-form h1{
    font-size: 2.5rem;
    width: 100%;
    margin-bottom: 2rem;
  }
  .quote-form h2{
    display: none;
  }
}
