.about{
  margin-bottom: 6rem;
  display: flex
}
.about-info {
  text-align: left;
}

.image-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-image {
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    justify-content: space-around;
    align-items: flex-start;
    text-align: left;
    padding: 2rem;
}
.about-image>div {
    padding-left: 4rem;
}

.about-image h4{
  font-size: 3rem;
  color: #0072bc;
  font-weight: 900;
}

.about-image img{
  width: 100%;
}

.about svg{
  height: 45px;
  width: 45px !important;
  margin-right: 16px;
  /* padding: 8px; */
}

.about path{
    fill: #c23030;
}

@media (max-width: 767px) {
  .about-image>div {
      padding-left: 0rem;
  }
  .about-image h4{
    font-size: 2rem;
  }
}
